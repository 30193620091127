.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: var(--mzn-spacing-2);

  & .inputWrapper {
    width: 250px;
  }

  & .label {
    display: flex;
    justify-content: space-between;
  }

  & .input {
    all: unset;
    width: 100%;
    height: 30px;
    border: 1px solid var(--mzn-color-divider);
    padding: 0 var(--mzn-spacing-2);
  }

  & .loginButton {
    width: 80px;
    margin-top: var(--mzn-spacing-2);
  }
}
