.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.body {
  height: 321px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--mzn-spacing-6) 0 !important;

  & > .wrapper {
    width: 744px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.button {
  width: 132px;
  height: 40px;
}
