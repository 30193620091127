.modal {
  & .footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    & > .leftButton {
      margin-right: var(--mzn-spacing-4);
    }
  }
}
