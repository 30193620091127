.action-btn-wrapper {
  display: flex;
  gap: 4px;
}

.line-break {
  display: block;
  width: 150px;
  word-wrap: break-word;
}

.preview-table {
  height: 21cm - 25mm;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.filter-and-table {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.page-break {
  height: 16px;
}
