@import '../shared-styles/index.module.scss';

.host {
  @extend %grid-filter;

  div:nth-child(1) {
    grid-column: span 4;
  }
  div:nth-child(2) {
    grid-column: span 4;
  }
  div:nth-child(3) {
    grid-column: span 4;
  }
  div:nth-child(4) {
    grid-column: span 5;
  }
  div:nth-child(5) {
    grid-column: span 5;
  }
}
