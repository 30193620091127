
.svg_with_popover {
  display: flex;
  gap: var(--mzn-spacing-4);
  position: relative;
  .svg_wrapper {
    flex-shrink: 0;
    svg {
      polygon {
        fill: green;
        fill-opacity: 0.2;
        &[category="WareHouse"] {
          fill: blue;
        }
        &[category="Equipment"] {
          fill: red;
          &[level="ZONE"][equipment-quantity="0"] {
            fill: grey;
          }
          &[level="STACK"][equipment-quantity="0"] {
            fill: grey;
          }
          &[level="SHELF"][equipment-quantity="0"] {
            fill: grey;
          }
        }
        &[active=true] {
          fill: yellow;
          fill-opacity: 1;
        }
        &:hover {
          cursor: pointer;
          fill-opacity: 0.5;
        }
        &[selected="true"] {
          fill-opacity: 0.5;
        }
      }
      g {
        text {
          pointer-events: none;
          &[active=true] {
            fill-opacity: 0.7;
          }
        }
      }
    }
  }
}
