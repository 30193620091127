.space {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.space2 {
  display: flex;
  flex-direction: column;
  gap: 6px;
  flex: 1
}