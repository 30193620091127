.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'control-panel control-panel'
    'left-wrapper right-wrapper';
  column-gap: 0.75rem;
  row-gap: 0.75rem;
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 150px);
}

.controlPanel {
  border: 1px solid var(--mzn-color-divider);
  grid-area: control-panel;
  height: 50px;
  padding: 0.5rem 0.5rem;
  display: flex;
  gap: var(--mzn-spacing-2);
}

.leftWrapper {
  grid-area: left-wrapper;
  border: 1px solid var(--mzn-color-divider);
  width: 100%;
  height: 100%;
}

.rightWrapper {
  grid-area: right-wrapper;
  border: 1px solid var(--mzn-color-divider);
  width: 100%;
  height: 100%;
}

.rightTitle {
  display: flex;
  gap: 10px;
  padding: 0 14px;
  margin-top: 10px;
}

.rctree {
  max-height: calc(100vh - 200px);
  overflow: auto;
}

.tableWrapper {
  padding: var(--mzn-spacing-2);

  & .table {
    min-height: 50vh;
  }
}

.loading {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

:global(.rc-tree
    .rc-tree-treenode
    span.rc-tree-iconEle.rc-tree-icon__customize) {
  margin-right: 8px !important;
}
