.host {
  display: inline-flex;
  gap: 8px;
  align-items: center;
}

.border {
  border: 1px solid grey;
  padding: 4px;
}
