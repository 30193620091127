.wrapper {
  width: 100%;
  padding: 8px 0;
  margin: 12px 0 0 0;
  border-top: 1px solid #e2e2e2;
}

.filters {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  column-gap: 4px;
}

.table {
  width: 100%;
  margin: 12px 0;
}

.tagWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 4px;
  flex-wrap: wrap;
}
