.fieldGroup {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  row-gap: 8px;
}

.fieldDescription {
  font-size: 13px;
  font-weight: 400;
}

.form {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  row-gap: 8px;
}

.conditions {
  display: grid;
  align-items: start;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 8px;
  column-gap: 12px;
  padding: 0 0 12px 0;
  border-bottom: 1px solid #d2d2d2;
}

.fullWidthField {
  width: 100%;

  :global(.mzn-rhf-base-field) {
    --width: 100% !important;
  }
}

.twoColumns {
  display: grid;
  grid-template-columns: 1fr 120px;
  column-gap: 12px;
}
