.wrapper {
  & > .title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > .buttonWrapper {
      display: flex;
      gap: 8px;
    }
  }

  & > .border {
    width: 100%;
    height: var(--mzn-spacing-4);
    margin-bottom: var(--mzn-spacing-5);
    border-bottom: 1px solid var(--mzn-color-divider);
  }

  & > .filterSearch {
    padding: var(--mzn-spacing-2) var(--mzn-spacing-4);
    border: 1px solid var(--mzn-color-divider);
    margin-bottom: var(--mzn-spacing-5);
  }
}

.table {
  & .ant-table-thead .ant-table-cell {
    background-color: green;
  }
}
