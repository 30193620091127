.host {
  :global(.mzn-form-field) {
    flex-direction: row;
    align-items: center;
    > label {
      justify-content: center;
      flex: 0 0 70px; 

      > span {
        width: max-content;
      }
    }
  }
}