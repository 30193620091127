@use '~@mezzanine-ui/system/palette' as palette;

.host {
  display: grid;
  grid-template-columns: 0.05fr 1fr;

  .label-font {
    font-size: 16px;
  }

  .label {
    margin: auto 0;
    font-weight: bold;
  }
}

.require-tag {
  background-color: palette.color('bg');
  font-size: 12px;
  border-radius: 2px;
  padding: 2px 4px;
  color: palette.color('text-primary');
  margin-left: 4px;
}

.field-wrapper {
  > div {
    width: 100% !important;
  }
}
