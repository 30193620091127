.flex {
  display: flex;
  align-items: center;
  margin-bottom: var(--mzn-spacing-5);
}

.filterFlex {
  display: flex;
  justify-content: space-between;
}

.table {
  min-height: 45vh;
}

.block {
  width: var(--mzn-spacing-5);
  height: 100%;
}
