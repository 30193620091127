.host {
  display: flex;
  gap: var(--mzn-spacing-4);
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--mzn-spacing-4);
}

.stickers-wrapper {
  height: 70vh;
  padding: 4px;
  overflow-y: scroll;
}

.barcode-scan {
  padding: 20px 0px 20px 8px;
  background-color: #ededed;
  .barcode-scan-input {
    width: 400px;
  }
}
