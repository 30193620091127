.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & .buText {
    margin-right: var(--mzn-spacing-3);
  }
}

.searchInput {
  display: flex;
  align-items: center;
  margin-left: var(--mzn-spacing-4);
}

.table {
  min-height: 50vh;
}

.popMenu {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
