.wrapper {
  & > .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & > .border {
    width: 100%;
    height: 28px;
    margin-bottom: 28px;
    border-bottom: 1px solid var(--mzn-color-divider);
  }

  & .link {
    text-decoration: none;
  }
}
