.container {
  width: 100%;
  height: 100%;
  row-gap: var(--mzn-spacing-6);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.title {
  font-family: "PingFang TC", sans-serif;
  font-weight: bold;
  font-size: var(--mzn-typography-h3-font-size);
  font-weight: var(--mzn-typography-h3-font-weight);
  flex-shrink: 0;
}

