.host { 
  display: flex;
  gap: 24px;
  
  .content {
    width: 100%;
    max-width: 152px;
  }
}

.divider {
  height: auto;
  margin: 0 !important
}