.wrapper {
  display: grid;
  row-gap: var(--mzn-spacing-4);

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
