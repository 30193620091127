.container {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 12px;
}

.column {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  row-gap: 6px;
}

.question {
  width: 100%;
  column-gap: 8px;
  display: grid;
  grid-template-columns: 150px 1fr 56px;
  align-items: center;

  .title {
    color: #414141;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    .titleDesc {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 10px;
      color: #979797;
      line-height: 1;
    }
  }
}

.parameter {
  width: 100%;
  column-gap: 8px;
  display: grid;
  grid-template-columns: 120px 1fr;
  align-items: center;

  .title {
    color: #414141;
  }

  .value {
    color: #121212;
  }
}

.creatorLine {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  column-gap: 12px;
}
