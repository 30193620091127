// input with an confirm btn
.input-search { 
  align-items: center; 
  width: 100%;
  display: flex;
  gap: var(--mzn-spacing-3);
}

// mainly for modal 
.row-block {
  padding: 24px;
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid #D9D9D9;
  height: fit-content;
}

.modal-body-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: var(--mzn-spacing-6);
  
}

.row-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.filter_form_wrapper {
  :global(.mzn-form-field) {
    flex-direction: row;
    align-items: center;
    > label {
      justify-content: center;
      flex: 0 0 70px; 

      > span {
        width: max-content;
      }
    }
  }
}

.text-area-full-width {
  > div > div {
    --width: 100% !important;
  }
}

.table {
  min-height: 50vh;
}

.table-header {
  overflow-x: auto;
}

%grid-filter{
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: var(--mzn-spacing-3);
  row-gap: var(--mzn-spacing-3);
}