.wrapper {
  width: 100%;

  :global(.mzn-typography--h2) {
    margin: 0 0 12px 0;
  }

  :global(.mzn-tabs__tab-bar) {
    width: 100%;
  }
}

.controller {
  display: grid;
  grid-template-columns: 360px 72px 180px;
  column-gap: 8px;
  align-items: end;

  button {
    transform: translate(0, -1px);
  }
}

.onlyAfterController {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 6px;
  height: 32px;

  .onlyAfterDesc {
    font-size: 13px;
    font-weight: 400;
    color: var(--mzn-color-text-primary);
  }
}

.recipes {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.recipe {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.recipeRoutingDivider {
  width: 1px;
  display: block;
  height: 40px;
  background-color: var(--mzn-color-border);
  position: relative;

  &:before {
    content: '';
    position: absolute;
    bottom: 5px;
    left: -9px;
    width: 12px;
    height: 1px;
    background-color: var(--mzn-color-border);
    transform: rotate(60deg);
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 5px;
    left: -2px;
    width: 12px;
    height: 1px;
    background-color: var(--mzn-color-border);
    transform: rotate(-60deg);
  }
}

.recipeRouting {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border-top: 1px solid var(--mzn-color-border);
  border-left: 1px solid var(--mzn-color-border);
}

.bomGroup {
  padding: 12px 16px;
  border-right: 1px solid var(--mzn-color-border);
  border-bottom: 1px solid var(--mzn-color-border);
}

.titleDecoration {
  font-size: 13px;
  font-weight: 400;
  color: var(--mzn-color-primary);
  margin: 0 0 0 12px;
}

.titleWithDivider {
  border-top: 1px solid var(--mzn-color-border);
  border-bottom: 1px solid var(--mzn-color-border);
  padding: 0;
  margin: 12px 0 !important;
}

.dataLine {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 4px 0;

  .dataLabel {
    font-size: 13px;
    line-height: 19px;
    font-weight: 400;
    width: 120px;
    flex-shrink: 0;
    color: var(--mzn-color-text-primary);
  }

  .dataValue {
    font-size: 13px;
    line-height: 19px;
    font-weight: 500;
    color: var(--mzn-color-text-primary);

    &.dataValueHighlight {
      color: var(--mzn-color-primary);
    }
  }
}

.routingGroup {
  padding: 12px 16px;
  border-right: 1px solid var(--mzn-color-border);
  border-bottom: 1px solid var(--mzn-color-border);
}

.sequence {
  color: var(--mzn-color-primary);
}
