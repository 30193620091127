.flex {
  display: flex;
  align-items: center;
  margin-bottom: var(--mzn-spacing-2);
}

.filterFlex {
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-bottom: var(--mzn-spacing-3);
}

.row1 {
  display: flex;
  flex-wrap: wrap;
  gap: 0 var(--mzn-spacing-4);
}

.treeSelect {
  border: 1px solid var(--mzn-color-divider);
}
