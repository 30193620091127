.filterWrapper {
  margin-top: 16px;
  margin-bottom: 16px;
  border: 1px solid var(--mzn-color-divider);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
  padding: 8px 12px;

  & > .filter {
    display: flex;
    align-items: center;
    gap: 8px;

    & > .yearMonthWrapper {
      display: flex;
      gap: 6px;
      align-items: center;
    }
  }

  & > .submitButton {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
  }
}

.modal {
  background: green;
  width: 576px;

  & .footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
}
