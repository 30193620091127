.wrapper {
  width: 100%;

  :global(.mzn-typography--h2) {
    margin: 0 0 12px 0;
  }

  :global(.mzn-tabs__tab-bar) {
    width: 100%;
  }
}

.recipeContainer {
  position: relative;

  .recipeHelper {
    position: absolute;
    left: 0;
    bottom: 0px;
    line-height: 19px;
    font-size: 13px;
    width: 200px;
    color: var(--mzn-color-primary);
  }
}

.controller {
  display: grid;
  grid-template-columns: repeat(12, 70px) 72px;
  column-gap: 10px;
  row-gap: 10px;
  align-items: end;

  > :nth-child(1) {
    grid-column: span 10;
  }

  > :nth-child(2),
  > :nth-child(3) {
    grid-column: span 6;
  }

  > :nth-child(4),
  > :nth-child(6) {
    grid-column: span 3;
  }

  > :nth-child(5) {
    grid-column: span 4;
  }

  > :nth-child(7) {
    grid-column: span 2;
  }

  button {
    transform: translate(0, -1px);
    grid-row: 1 / 3;
    grid-column: 13;
    align-self: center;
  }
}

.recipes {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  :global(.mzn-tabs__tab-bar) {
    margin-bottom: 0;
  }
}

.iconButton {
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 12px;
  height: 100%;
}

.recipeLine {
  line-height: 24px;
  font-size: 16px;
  color: var(--mzn-color-primary);
}
