.selector-wrapper {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: var(--mzn-spacing-4);
  row-gap: var(--mzn-spacing-6);
}

.custom-row[strike-through='true'] {
  &::before {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 0;
    border-bottom: 1px solid #111;
    width: 100%;
  }

  & > :first-child {
    position: relative !important;
    background-color: white !important;
  }
}

.modal-width {
  width: 80vw !important;
  min-width: 20vw;
}
