.table {
  min-height: 29vh;
}

.tableHeader {
  display: flex;
  justify-content: space-between;
}

.input {
  width: 160px;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--mzn-spacing-4);
}

.modalBodyWithBorder {
  border: 1px solid var(--mzn-color-divider);
  padding: var(--mzn-spacing-5);
  width: 784px;

  & > .body {
    display: flex;
    flex-wrap: wrap;
    margin-top: var(--mzn-spacing-4);
    gap: var(--mzn-spacing-4) var(--mzn-spacing-5);
  }
}

.expandTableWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--mzn-spacing-4);
  padding: var(--mzn-spacing-4);

  & > .expandTable {
    display: flex;
    gap: var(--mzn-spacing-5);
    flex-wrap: wrap;

    & > .td {
      display: flex;
      gap: var(--mzn-spacing-1);
    }
  }
}

.modalCaculateTableColumnsIndex {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
