.modal {
  width: 576px;

  & .body {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    padding-bottom: 16px !important;

    & .content {
      display: flex;
      align-items: center;
      gap: 4px;

      & .label {
        width: 60px;
      }
    }
  }

  & .footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
}
