.host {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: var(--mzn-spacing-3);
  row-gap: var(--mzn-spacing-3);

  div:nth-child(1) {
    grid-column: span 4;
  }
  div:nth-child(2) {
    grid-column: span 4;
  }
  div:nth-child(3) {
    grid-column: span 4;
  }
  div:nth-child(4) {
    grid-column: span 4;
  }
  div:nth-child(5) {
    grid-column: span 4;
  }
  div:nth-child(6) {
    grid-column: span 4;
  }
  div:nth-child(7) {
    grid-column: span 4;
  }
}
