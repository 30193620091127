.svg_viewer {
  display: grid;
  gap: var(--mzn-spacing-1);
  .svg_with_popover {
    position: relative;
    .popover_wrapper {
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 2;
      pointer-events: none;
    }
    .svg_wrapper {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      svg {
        polygon {
          fill: green;
          fill-opacity: 0.2;
          &[category="WareHouse"] {
            fill: blue;
            &[active=true] {
              fill: darkblue;
              fill-opacity: 1;
            }
          }
          &[category="Equipment"] {
            fill: red;
            &[active=true] {
              fill: yellow;
              fill-opacity: 1;
            }
          }
          &:hover {
            cursor: pointer;
            fill-opacity: 0.5;
          }
        }
        text {
          pointer-events: none;
          &[active=true] {
            fill-opacity: 0.7;
          }
        }
      }
    }
  }
}

.equipmentList {
  padding: 0 0 0 1em;
  margin: 0;

  li {
    list-style: square;
  }
}
