.tracking_tree_wrapper {
  position: relative;
  width: 100%;
  height: 600px;
  border: 4px solid #000;
}

.context_menu_wrapper {
  position: absolute;
  width: 200px;
  background-color: #f9f9f9;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  border-radius: 1px;
  padding: 16px 0;
  z-index: 999;

  :global(.ant-space-item) {
    width: 100%;
    button {
      width: 100%;
      display: flex;
      justify-content: start;
    }
  }
}

.flow_analysis_graph_wrapper {
  position: relative;
  height: calc(600px - 8px) !important;
}

.container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto auto 1fr;
  row-gap: 12px;
}

.filters {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  column-gap: 12px;

  button {
    transform: translate(0, -1px);
  }
}
