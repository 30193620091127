.container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto auto 1fr;
  row-gap: 12px;
}

.filters {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  column-gap: 12px;

  button {
    transform: translate(0, -1px);
  }
}
