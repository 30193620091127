.wrapper {
  & > .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & > .border {
    width: 100%;
    height: var(--mzn-spacing-4);
    margin-bottom: var(--mzn-spacing-5);
    border-bottom: 1px solid var(--mzn-color-divider);
  }

  height: calc(100% - 32px);
}
