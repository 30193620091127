.wrapper {
  & .titleWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & .topContentWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 12px;

    & .content1 {
      width: 33%;
      display: flex;
      align-items: center;
      gap: 6px;
    }

    & .content2 {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 6px;
    }
  }

  & .bottomContent {
    display: flex;
    gap: 6px;
    margin-top: 12px;

    & p {
      margin: 0;
    }
  }
}

@media print {
  ::-webkit-scrollbar {
    display: none;
  }

  @page {
    margin: 5mm; /* Adjust margin here */
  }

  .wrapper,
  .wrapper * {
    width: auto;
    height: auto;
    visibility: visible;
  }

  .wrapper {
    position: absolute;
    left: 0;
    top: 0;
    overflow-y: auto;
  }

  .titleWrapper {
    text-align: center; /* Centering content horizontally */
  }

  .wrapper .ant-row {
    margin-top: 15px; /* Reduced margin-top here */
    margin-bottom: 30px;
  }

  #firstTable {
    page-break-before: avoid;
  }

  #secondTable {
    page-break-before: always;
  }

  thead {
    display: table-header-group;
  }

  tfoot {
    display: table-footer-group;
  }
}  
