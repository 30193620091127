.navigation {
  padding-right: 20px !important;

  &:global(.mzn-navigation) {
    background-color: #ededed;
    overflow-y: auto;
  }

  .link {
    color: inherit;
    text-decoration: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
