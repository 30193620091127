.label-font {
  font-size: 16px !important;
}

.label {
  margin: auto 0 !important;
  font-weight: bold;
}

.already-select-container {
  display: grid;
  grid-template-columns: 0.5fr 2px 1fr;

  .input-field {
    align-self: baseline;
  }
}

.select {
  min-width: 90px !important;
  width: 90px !important;
}

.actionTable {
  thead[class='mzn-table__header-fixed'] {
    tr[class='mzn-table__header'] {
      th[class='mzn-table__header__cellWrapper'] {
        &:nth-last-child(1) {
          position: sticky !important;
          z-index: 2;
          right: 0;
        }

        &:nth-last-child(2) {
          position: sticky !important;
          z-index: 2;
          right: 200px;
        }
      }
    }
  }

  tbody[class='mzn-table__body'] {
    tr {
      td[class='mzn-table__body__row__cellWrapper'] {
        &:nth-last-child(1) {
          position: sticky !important;
          z-index: 2;
          right: 0;
        }

        &:nth-last-child(2) {
          position: sticky !important;
          z-index: 2;
          right: 200px;
        }
      }
    }
  }
}
