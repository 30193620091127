.filter_form_wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: var(--mzn-spacing-3);
  row-gap: var(--mzn-spacing-2);

  :global(.mzn-form-field) {
    flex-direction: row;
    align-items: center;
    > label {
      flex: 0 0 80px;
    }
  }

  div:nth-child(1) {
    grid-column: span 3;
  }
  div:nth-child(2) {
    grid-column: span 3;
  }
  div:nth-child(3) {
    grid-column: span 1;
  }

  .searchInput {
    grid-column: 2 / span 2;
  }
}
