.filterWrapper {
  border: 1px solid var(--mzn-color-divider);
  padding: 12px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
  margin: 16px 0;

  & > .filter {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  & > .radioFilter {
    display: flex;
    align-items: center;
    width: 60%;
  }

  & > .submitButton {
    display: flex;
    justify-content: flex-end;
    flex: 1;
  }
}

.table {
  min-height: 40vh;
}
