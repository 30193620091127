.space {
  display: flex;
  gap: 12px;
  align-items: flex-end;
  margin-bottom: 12px;
}

.itemSpace {
  display: flex;
  border-bottom: 1px solid #e0e0e0;
}

.itemSpace:last-child {
  border-bottom: none;
}

.itemCompnentName {
  width: 100px;
}

.itemUnit {
  width: 120px;
}