.footer {
  display: flex;
  justify-content: flex-end;
}

.body {
  width: 744px;

  & .border {
    height: var(--mzn-spacing-6);
    margin-bottom: var(--mzn-spacing-6);
    width: 100%;
    border-bottom: 1px solid var(--mzn-color-divider);
  }
}
