.work_orders_wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--mzn-spacing-4);

  .filter_form_wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: var(--mzn-spacing-3);
    row-gap: var(--mzn-spacing-2);

    :global(.mzn-form-field) {
      flex-direction: row;
      align-items: center;
      > label {
        // flex: 1 0 60px;
        width: fit-content;
        white-space: nowrap;
        margin-right: 12px;
      }
    }
  }

  :global(.mzn-table) {
    height: 50vh;
  }
}
