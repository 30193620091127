.actionGroup {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.changedField {
  font-weight: 700;
  color: var(--mzn-color-error-light);
}
