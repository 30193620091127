
.wrapper {
  display: grid;
  row-gap: var(--mzn-spacing-4);

  .header-wrapper {
    display: flex;
    justify-content: space-between;
  }
}

.input-search {
  max-width: 450px;
  width: 100%
 }