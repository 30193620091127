.wrapper {
  & .filterButton {
    margin-right: var(--mzn-spacing-2);
  }

  & > .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & > .border {
    width: 100%;
    height: 28px;
    margin-bottom: 28px;
    border-bottom: 1px solid var(--mzn-color-divider);
  }

  & .filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--mzn-spacing-5);

    & > .leftFilter {
      display: flex;
      align-items: center;

      & > .year {
        margin-right: var(--mzn-spacing-2);
        color: var(--mzn-color-text-secondary);
      }

      & > .date {
        margin-left: var(--mzn-spacing-5);
        margin-right: var(--mzn-spacing-3);
        color: var(--mzn-color-text-secondary);
      }

      & > .dateButton {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: var(--mzn-spacing-3);
      }
    }
  }

  & .table {
    min-height: 50vh;
  }
}
