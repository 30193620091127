.page_content {
  display: block;

  form {
    margin-bottom: 24px;
  }
}

.modal_content {
  margin-top: 20px;
  display: grid;
  gap: 24px;
}