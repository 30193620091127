.wrapper {
  border: 2pt solid black;
  border-radius: 20px;
  padding: 12px;
  height: 100%;
}

.middle {
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 12px;
  justify-content: center;
  height: fill-available;
  margin-top: 10pt;

  canvas {
    margin: 0 auto;
    display: block;
  }

  h1 {
    font-size: 50px;
  }
}
