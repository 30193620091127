.table {
  min-height: 45vh;
}

.tableheader {
  display: flex;
  justify-content: space-between;
}

.expandTableWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: var(--mzn-spacing-4);
  padding: var(--mzn-spacing-4);

  & > .expandTableAction {
    display: flex;
  }
}

.expandTable {
  display: flex;
  max-width: 40%;
  gap: var(--mzn-spacing-5);
  flex-wrap: wrap;

  & > .td {
    display: flex;
    gap: var(--mzn-spacing-1);
  }
}

.modalBody {
  display: flex;
  gap: var(--mzn-spacing-4);
  margin-top: var(--mzn-spacing-4);
}

.submitButtonWrapper {
  margin-top: var(--mzn-spacing-4);
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
