.wrapper {
  & > .titleWrapper {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & > .filterWrapper {
    border: 1px solid var(--mzn-color-divider);
    width: 100%;
    padding: var(--mzn-spacing-3) var(--mzn-spacing-4);

    & > .filterTitleWrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid var(--mzn-color-divider);

      & .noRotateIcon {
        transform: rotate(0);
        transition: 0.3s;
      }

      & .rotateIcon {
        transform: rotate(180deg);
        transition: 0.3s;
      }
    }

    & > .formFieldsWrapper {
      margin-top: var(--mzn-spacing-4);
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: var(--mzn-spacing-4);
    }

    & .filters {
      width: 100%;
      height: auto;
      display: flex;
      gap: var(--mzn-spacing-4);
    }

    & .submitButton {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      align-items: center;
    }
  }

  & > .tableWrapper {
    margin-top: var(--mzn-spacing-4);

    & .table {
      min-height: 40vh;
    }
  }
}
