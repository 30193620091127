.shift-action-btn {
  float: right;
}

.input-fields-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .barcode-scan-input {
    width: 600px;
  }
}

.shift-table {
  min-height: 10vh;
}

.custom-row-expand {
  tr[class='mzn-table__body__row mzn-table__body__row--highlight'],
  tr[class='mzn-table__body__row'] {
    td:first-child {
      div {
        div {
          visibility: hidden;
          width: 0;
        }
      }
    }
  }
}
