.wrapper {
  width: 100%;

  :global(.ant-descriptions) {
    :global(.ant-descriptions-item) {
      padding: 0;
    }
  }

  :global(.ant-table) {
    :global(.ant-table-cell) {
      padding: 8px 0px;
    }
  }

  & .titleWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    & h5 {
      margin: 0;
      font-size: 20px;
    }
  }

  & .topContentWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 12px;

    & .content {
      width: 33%;
      display: flex;
      align-items: center;
      gap: 6px;
    }
  }

  & .bottomContent {
    display: flex;
    gap: 6px;
    margin-top: 12px;
    white-space: pre-line;

    & p {
      margin: 0;
    }
  }
}

@media print {
  ::-webkit-scrollbar {
    display: none;
  }

  @page {
    margin: 5mm; /* Adjust margin here */
  }

  .wrapper,
  .wrapper * {
    width: auto;
    height: auto;
    visibility: visible;
  }

  .wrapper {
    position: absolute;
    left: 0;
    top: 0;
    overflow-y: auto;
  }

  .titleWrapper {
    text-align: center; /* Centering content horizontally */
  }

  thead {
    display: table-header-group;
  }

  tfoot {
    display: table-footer-group;
  }
}
