.filter_form_wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: var(--mzn-spacing-3);
  row-gap: var(--mzn-spacing-2);

  :global(.mzn-form-field) {
    flex-direction: row;
    align-items: center;
    > label {
      flex: 0 0 70px;
    }
  }

  div:nth-child(1) {
    grid-column: span 2;
  }
  div:nth-child(2) {
    grid-column: span 2;
  }
  div:nth-child(3) {
    grid-column: span 1;
  }
  div:nth-child(4) {
    grid-column: span 5;
    display: flex;
    align-items: center;
    column-gap: var(--mzn-spacing-3);
    div:first-child {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      min-width: 0;
      max-width: 100%;
      overflow-x: scroll;
      gap: var(--mzn-spacing-2);
    }
    button:last-child {
      margin-right: 20px;
    }
  }
}
