.wrapper {
  // border: 1px solid var(--mzn-color-divider);
  // padding: var(--mzn-spacing-4);
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;
  align-items: center;
}

.divider {
  border-bottom: 1px solid var(--mzn-color-divider);

  @extend .col-span-6;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-5 {
  grid-column: span 5 / span 5;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.col-span-1:nth-child(odd) {
  // background: green;
}

.col-span-1:nth-child(even) {
  // background: purple;
}
