@page {
  size: A4 Landscape;
  margin: 1.5cm;
}

@media print {
  html,
  body {
    padding: 0;
    width: 210mm;
    height: 139.7mm;
  }
  body {
    header {
      display: none !important;
    }
  }
  .sectionToPrint {
    visibility: visible;
    position: absolute;
    left: 0;
    top: 0;
    padding: 0;
    width: 210mm;
    height: 139.7mm;
  }

  .custom-page {
    /*min-height: 14cm;*/ /*chrome要註解*/
    width: 210mm;
    /*page-break-after:avoid;*/ /*firefox專用*/
    page-break-after: always; /*chrome專用*/
  }

  .custom-table {
    page-break-inside: auto;
  }

  .custom-table tr,
  .custom-table-detail tr,
  .custom-table-footer tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }

  /*firefox要隱藏*/
  /*.custom-table-detail tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }*/
}

.custom-page {
  width: 210mm;
  margin: 0 auto;
  font-family: DFKai-sb;
}

.custom-page {
  td {
    word-break: break-all;
    white-space: pre-wrap;
  }
}

.detail-wrap {
}

.cust-table-header,
.custom-table {
  /*text-align: center;*/
}

.custom-table,
.custom-table-detail tr td {
  /*border: 1px solid #000;
  border-bottom:none;*/
  /*vertical-align: middle;*/
  // word-break: break-all;
  // white-space: nowrap;
  // overflow: hidden;
  /*text-overflow:ellipsis;*/
}

.custom-table-detail tr th {
  border: 1px solid #000;
  // height: 23px;
  font-weight: lighter;
}

.cust-table-header,
.custom-table,
.custom-table-detail,
.custom-table-footer {
  width: 100%;
  border-collapse: collapse;
  // table-layout: fixed;
  word-break: break-all;
}

.custom-table,
.custom-table-detail {
  table-layout: fixed;
}

.cust-table-header td,
.custom-table td,
.custom-table-detail td,
.custom-table-footer td {
  border: 1px solid #000;
  border-bottom: none;
  padding: 1px 3px;
  // height: 25px;
}

.custom-table-footer {
}

.custom-table-footer td {
  border-bottom: 1px solid;
}

.page_footer {
  /*margin-top: 5px;*/
  page-break-inside: avoid;
  /*page-break-after: auto;*/
}

.page_footer span {
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .custom-table-detail tr th {
    /*height: 24px;*/
  }
}

@-moz-document url-prefix() {
  .custom-table-detail tr th {
  }
  .custom-table-detail tr td {
  }
}

.sectionToPrint {
  > * {
    margin-top: 48px;
  }
}

.w75 {
  width: 75px !important;
}

.wBig {
  font-size: 1.2rem;
}
