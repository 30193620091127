.wrapper {
  & > .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & .border {
    width: 100%;
    height: var(--mzn-spacing-4);
    margin-bottom: var(--mzn-spacing-4);
    border-bottom: 1px solid var(--mzn-color-divider);
  }

  & > .filters {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: var(--mzn-spacing-4) var(--mzn-spacing-6);

    & > .buttonWrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }

  & > .tagForm {
    width: 100%;

    & > .formWrapper {
      display: flex;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      gap: var(--mzn-spacing-4);

      & > .tags {
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        gap: var(--mzn-spacing-4);
      }
    }
  }
}

.input {
  width: 160px;
}
