@mixin flex-row {
  display: flex;
  align-items: center;
  gap: 24px;
}

.row-block {
  padding: 24px;
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid #D9D9D9;
  height: fit-content;

  & > h4 + div {
    margin-top: 16px;
  }

  .orders-info, .material-info { 
    @include flex-row;
  
    .content {
      width: 100%;
      max-width: 152px;
    }

    .spec {
      width: 100%;
      max-width: 220px;
    }
  }
}

.action-container{
  @include flex-row
}

.divider {
  height: auto;
  margin: 0 !important
}

.return-btn {
  color: #AF1C12 !important;
}