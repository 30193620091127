.wrapper {
  width: 100%;

  :global(.mzn-typography--h2) {
    margin: 0;
  }

  :global(.mzn-tabs__tab-bar) {
    width: 100%;
  }
}

.form {
  padding: 12px 0;
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(2, 1fr);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
