.filter_form_wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: var(--mzn-spacing-3);
  row-gap: var(--mzn-spacing-2);

  :global(.mzn-form-field) {
    flex-direction: row;
    align-items: center;
    > label {
      flex: 0 0 40px;
    }
  }
}
