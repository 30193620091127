.modal {
	[class="mzn-text-field__prefix"] {
		display: none;
	}

	& .searchInput {
		& input {
			padding-left: var(--mzn-spacing-4);
		}
	}

	& .footer {
		display: flex;
		justify-content: flex-end;

		& .button {
			margin-right: var(--mzn-spacing-4);
		}
	}

	& .header {
		& > h3 {
			display: flex;
			& > i {
				margin-right: var(--mzn-spacing-4);
			}
		}
	}
}
