.wrapper {
  width: 100%;
  padding: 12px 0;
}

.inputBox {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 80px;
  column-gap: 8px;
  position: relative;
}

.inputWrapper {
  width: 100%;
  height: 40px;
  position: relative;
  font-size: 18px;
  line-height: 40px;
  font-weight: 400;
  border: 1px solid #e2e2e2;
  border-radius: 2px;
  font-family: monospace;
}

.input {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border: 0;
  width: 100%;
  outline: none;
  color: transparent;
  background-color: transparent;
  padding: 0 0.5em;
  caret-color: #212121;
}

.viewer {
  width: 100%;
  height: 100%;
  position: relative;
  color: #212121;
  pointer-events: none;
  display: block;
  padding: 0 0.5em;
  position: relative;

  &.failed {
    color: #f94144;
  }

  .suggestion {
    color: #d2d2d2;
  }

  .nowSectionHint {
    position: absolute;
    right: 0.5em;
    font-size: 16px;
    top: 0;
    color: #d2d2d2;
  }

  .failed {
    color: #f94144;
  }
}

.matchedKeysWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 4px;
  column-gap: 4px;
  padding: 8px 0;
  flex-wrap: wrap;

  :global(.mzn-tag) {
    &[data-color="#f3722c"] {
      color: #f3722c !important;
    }
    &[data-color="#277da1"] {
      color: #277da1 !important;
    }
    &[data-color="#577590"] {
      color: #577590 !important;
    }
    &[data-color="#f8961e"] {
      color: #f8961e !important;
    }
    &[data-color="#4d908e"] {
      color: #4d908e !important;
    }
    &[data-color="#f9844a"] {
      color: #f9844a !important;
    }
    &[data-color="#43aa8b"] {
      color: #43aa8b !important;
    }
    &[data-color="#f9c74f"] {
      color: #f9c74f !important;
    }
    &[data-color="#90be6d"] {
      color: #90be6d !important;
    }
    &[data-color="#0f4c5c"] {
      color: #0f4c5c;
    }
    &[data-color="#3d348b"] {
      color: #3d348b;
    }
    &[data-color="#5c8001"] {
      color: #5c8001;
    }
    &[data-color="#918450"] {
      color: #918450;
    }
  }
}

.nextLevelKeysWrapper {
  padding: 12px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 6px;
  row-gap: 10px;
  line-height: 16px;

  .nextLevelKey {
    display: grid;
    grid-template-columns: 48px 1fr;
    column-gap: 6px;
    font-weight: 400;
    color: #575757;

    .label {
      font-weight: 500;
      color: #212121;
    }
  }
}
