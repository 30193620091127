.flexBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.border {
  width: 100%;
  height: 24px;
  margin-bottom: var(--mzn-spacing-5);
  border-bottom: 1px solid var(--mzn-color-divider);
}

.table {
  min-height: 60vh;
}
