.container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: var(--mzn-spacing-4);
  row-gap: var(--mzn-spacing-6);
  background-color: var(--mzn-color-bg);
  border-radius: var(--mzn-spacing-2);
  padding: var(--mzn-spacing-6);
  width: 100%;
}

.title {
  grid-column: span 4;
}

.address {
  grid-column: span 3;
}

.name {
  grid-column: span 2;
}
