.host {
  display: flex;
  gap: var(--mzn-spacing-4);
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--mzn-spacing-4);
  height: 250pt;
}
