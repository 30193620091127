.modal_wrapper {
  &:global(.mzn-modal) {
    width: 1200px;
  }
  :global(.mzn-modal__body) {
    display: grid;
    max-height: 630px;
    overflow: scroll;
    background-color: #F5F5F5;
    gap: var(--mzn-spacing-6);
    > form {
      display: grid;
      gap: var(--mzn-spacing-6);
    }
    .field_wrapper {
      display: flex;
      align-items: flex-end;
    }
    .form_group {
      display: flex;
      flex-direction: column;
      padding: var(--mzn-spacing-6);
      background-color: #fff;
      border: 1px solid var(--mzn-color-border);
      gap: var(--mzn-spacing-4);
      .detail_wrapper {
        display: flex;
        padding-bottom: var(--mzn-spacing-4);
        border-bottom: 1px solid var(--mzn-color-border);
        > div {
          display: flex;
          flex-direction: column;
          gap: var(--mzn-spacing-1);
        }
        div:not(:first-child) {
          padding: 0 var(--mzn-spacing-6);
          border-left: 1px solid var(--mzn-color-border);
        }
      }
      .flex_fields_group {
        display: flex;
        gap: var(--mzn-spacing-6);
      }
    }
  }
  :global(.mzn-modal__footer) {
    display: flex;
    justify-content: flex-end;
    gap: var(--mzn-spacing-4);
  }
}

.logs_wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: var(--mzn-spacing-2);
  .log {
    display: flex;
    align-items: center;
    gap: var(--mzn-spacing-2);
  }
}

.table {
  min-height: 200px;
}
