.formRow {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  :global(.mzn-form-field) {
    width: 100%;
  }

  :global(.mzn-form-field--error) {
    margin-bottom: 22px;
  }
}

.formColLeft {
  width: 400px;
}

.formCol {
  width: 400px;
}