.info {
  width: 100%;
  display: flex;
  align-items: center;
  gap: var(--mzn-spacing-5);

  & > .content {
    width: 152px;
  }

  & > .fakeBorder {
    background: var(--mzn-color-divider);
    width: 1px;
    height: 48px;
  }
}

.border {
  background-color: var(--mzn-color-divider);
  width: 100%;
  height: 1px;
  margin: var(--mzn-spacing-4) 0;
}
