.modal_wrapper {
  &:global(.mzn-modal) {
    width: 864px;
  }
  :global(.mzn-modal__body) {
    background-color: #F5F5F5;
  }
  :global(.mzn-modal__footer) {
    display: flex;
    justify-content: flex-end;
    gap: var(--mzn-spacing-4);
  }
}
