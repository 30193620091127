.container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
}

.filter {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  min-height: 100%;
  margin-top: 20px;
  padding: 20px;
  background-color: var(--mzn-color-bg);
  border-radius: var(--mzn-spacing-2);
}

.createTable {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  min-height: 100%;
  margin-top: 20px;
  padding: 20px;
  row-gap: var(--mzn-spacing-4);
  column-gap: var(--mzn-spacing-5);
  background-color: var(--mzn-color-bg);
  border-radius: var(--mzn-spacing-2);
}

.creatingForm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  row-gap: var(--mzn-spacing-5);

  > * {
    width: 100%;
  }
}

.modal {
  border-radius: var(--border-radius-surface);
}

.modalTitle {
  padding: var(--mzn-spacing-6);
  font-size: var(--mzn-typography-h3-font-size);
  font-weight: var(--mzn-typography-h3-font-weight);
  font-family: 'PingFang TC', sans-serif;
}

.modalContent {
  display: flex;
  flex-direction: column;
  gap: var(--mzn-spacing-6);
  font-size: var(--mzn-typography-h5-font-size);
}

.formContent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  row-gap: var(--mzn-spacing-5);
  column-gap: var(--mzn-spacing-5);
  width: 100%;
  height: 100%;
  margin-bottom: 20px;

  > * {
    flex-shrink: 0;
  }
}

.tag {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: var(--mzn-spacing-5);
  column-gap: var(--mzn-spacing-5);
  width: 100%;
  height: 100%;
  margin-bottom: 20px;

  > * {
    flex-shrink: 0;
  }
}

.materialId {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  row-gap: var(--mzn-spacing-5);
  column-gap: var(--mzn-spacing-5);
  width: 100%;
  height: 100%;
  overflow: auto;
  margin-bottom: 10px;
  margin-left: 30px;

  > * {
    flex-shrink: 0;
  }
}

.formTitle {
  flex-shrink: 0;
  width: 100%;
}

.buttonGroup {
  grid-column-start: 1;
  grid-column-end: 5;
  justify-content: end;
  margin-top: 20px;
  margin-bottom: 20px;
}

.addBtn {
  margin-left: auto;
  margin-top: 20px;
  justify-content: end;
}

.searchBtn {
  grid-column-start: 1;
  grid-column-end: 5;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.backBtn {
  padding-bottom: var(--mzn-spacing-3);
}

.searchFilter {
  background-color: var(--mzn-color-bg);
  padding: 20px;
}

.table {
  font-family: 'PingFang TC', sans-serif;
  color: var(--mzn-color-text-secondary);
  font-size: var(--mzn-typography-h6-font-size);
  font-weight: var(--mzn-typography-h6-font-weight);
  margin-top: 20px;
}

.tableContainer {
  flex-grow: 1;
  height: 100px;
}

.tableBody {
  font-family: 'PingFang TC', sans-serif;
  font-size: var(--mzn-typography-input2-font-size);
  font-weight: var(--mzn-typography-input2-font-weight);
  color: black;
}

.space {
  display: flex;
  gap: 12px;
  align-items: flex-end;
  margin-bottom: 12px;
}
