//  notes: 將背景定意在 tr > td 的原因是：
//  tr 那一層的 background-color 用來做為 hover 時的顏色

.table-row {
  & > td {
    background-color: rgba(128, 128, 128, 0.294) !important;
  }
}

.table-row[disabled='false'] {
  & > td {
    background-color: white !important;
  }
}

.barcode-scan-input {
  width: 600px;
}
