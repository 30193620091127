.spItemMatchTable {
  :global(.deleted-row) {
    background-color: lightgrey;
  }

  :global(.deleted-row::after) {
    border: 1px solid #777;
    width: 100%;
    height: 1px;
    content: '';
    position: absolute;
    top: 23px;
  }

  :global(.deleted-row > *) {
    background-color: lightgrey;
  }
}

.spPurchaseTable {
  :global(.table-edit-input) {
    // background-color: yellow;
    min-width: 80px;
    width: 80px;
    input {
      padding: 0 4px;
    }
  }
}
