.table {
  min-height: 29vh;
}

.tableheader {
  display: flex;
  justify-content: space-between;
}

.actionWrapper {
  display: flex;
  gap: 8px;
}

.link-disabled {
  pointer-events: none;
}

:global(.mzn-message__root > .mzn-message) {
  pointer-events: auto !important;
}
