.page {
  display: grid;
  gap: var(--mzn-spacing-4);
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .filter {
    display: grid;
    gap: var(--mzn-spacing-4);
    border: 1px solid var(--mzn-color-border);
    padding: var(--mzn-spacing-3) var(--mzn-spacing-4);
    .fields_wrapper {
      display: grid;
      align-items: center;
      grid-template-columns: 80px 80%;
      gap: var(--mzn-spacing-2);
    }
    .action_wrapper {
      display: flex;
      justify-content: flex-end;
    }
  }
}