.wrapper {
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: flex-start;
  height: calc(100vh - 65px);
}

.tableWrapper {
  width: 1000px;
  flex-shrink: 0;
  height: calc(100% + 32px);
  border-right: 1px solid #efefef;
  position: relative;
  overflow: hidden;
  margin: -16px 0 0 0;
  padding: 16px 16px 16px 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  row-gap: 12px;
}

.chartWrapper {
  flex-grow: 1;
  height: 100%;
}

.buttonLine {
  padding: 16px 0;
}

.filters {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  column-gap: 12px;
}

.centerVertical {
  align-items: center;
}
