.container {
  display: grid;
  column-gap: var(--mzn-spacing-4);
  row-gap: var(--mzn-spacing-5);
  background-color: var(--mzn-color-bg);
  border-radius: var(--mzn-spacing-2);
  padding: var(--mzn-spacing-6);
  width: 100%;
}

.title {
  grid-column: span 4;
}

.convert {
  justify-self: end;
}

.label {
  color: var(--mzn-color-action-inactive);
  display: flex;
  align-items: flex-end;
  width: 46px;
}

.errMsg {
  color: var(--mzn-color-error);
  display: flex;
  align-items: center;
  gap: 4px;
  &Icon {
    font-size: 1rem;
  }
}
