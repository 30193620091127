.container {
  width: 100%;
}

.table {
  border: 1px solid var(--mzn-color-border);
  margin: var(--mzn-spacing-2) 0;
}

.select_unit {
  min-width: 100px !important;
  width: 100px !important;
}