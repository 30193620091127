.wrapper {
  height: 100%;
  display: flex;
  align-items: center;

  & .input {
    max-width: 415px;
    padding-left: 0;
  }

  & .calendarButton {
    position: absolute;
    z-index: -1;
    width: 32px;
    height: 32px;
    margin-left: var(--mzn-spacing-3);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  & .calendarWrapper {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    & div[class~='mzn-text-field'] {
      background: none;
      opacity: 0;
    }
  }
}
