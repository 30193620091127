.filter_form_wrapper {
  :global(.mzn-form-field) {
    width: 100%;
    flex-direction: row;
    align-items: center;
    > label {
      flex: 0 0 70px;
    }
  }
}
