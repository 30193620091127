.modal {
  max-width: 40vw;
}

.half-space {
  width: 50%;
  > p {
    margin-right: var(--mzn-spacing-3) !important; 
  }
}




