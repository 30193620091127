.container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
}

.formContent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  row-gap: var(--mzn-spacing-5);
  width: 100%;
  height: 100%;
  overflow: auto;

  > * {
    flex-shrink: 0;
  }
}

.formTitle {
  flex-shrink: 0;
  width: 100%;
}

.buttonGroup {
  padding-top: var(--mzn-spacing-4);
  padding-bottom: var(--mzn-spacing-4);
}

.backBtn {
  padding-bottom: var(--mzn-spacing-3);
}
