.hide-default-expand-column {
  tr[class='mzn-table__body__row mzn-table__body__row--highlight'],
  tr[class='mzn-table__body__row'] {
    td:first-child {
      div {
        div {
          visibility: hidden;
          width: 0;
        }
      }
    }
  }
}
