.wrapper {
  & .flex {
    display: flex;
    justify-content: space-between;
  }

  & .filter {
    display: flex;
    align-items: center;
    margin-bottom: var(--mzn-spacing-5);

    & > .text {
      margin-right: var(--mzn-spacing-2);
      margin-left: var(--mzn-spacing-4);
    }

    & .searchInput {
      width: 240px;
    }
  }

  & .header {
    justify-content: space-between;
  }

  & .table {
    min-height: 50vh;
  }

  & .filePath {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
