.container {
  width: 100%;
  display: grid;
  grid-template-columns: 150px 1fr 32px;
  row-gap: 22px;
  column-gap: 8px;

  :global(.mzn-form-field__message) {
    --mzn-form-field-severity-color: red;
  }
}

.formActionLine {
  grid-column: span 3;
  width: 100%;
  text-align: left;
}

.spanValue {
  grid-column: span 2;
}
