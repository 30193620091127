.tabPane {
  background-color: gray;
}

.tabBar {
  margin-bottom: 0 !important;
}

.save-btn {
  float: right;
  width: 150px;
}
