.wrapper {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 12px;
  flex-direction: column;
}

.localFormWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 12px;
  flex-direction: column;
  width: 100%;
}

.localFilter {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 8px;
}

.tableWrapper {
  width: 100%;
  overflow: auto;
}

.table {
  width: 2500px;

  --mzn-typography-input2-font-size: 13px;
  --mzn-typography-input2-font-family: monospace;
}

.tableBody {
  height: 100%;
}

.detailHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 8px;
  width: 100%;
  row-gap: 8px;
  flex-wrap: wrap;
}

.chartWrapper {
  width: 100%;
}
