@import '../Material/shared-styles/index.module.scss';

.host {
  @extend %grid-filter;

  div:nth-child(1) {
    grid-column: span 3;
  }
  div:nth-child(2) {
    grid-column: span 3;
  }
  div:nth-child(3) {
    grid-column: span 3;
  }
  div:nth-child(4) {
    grid-column: span 3;
  }
  div:nth-child(5) {
    grid-column: span 3;
  }

  div:nth-child(6) {
    grid-column: span 3;
  }
  div:nth-child(7) {
    grid-column: span 3;
  }
  div:nth-child(8) {
    grid-column: span 3;
  }
  div:nth-child(9) {
    grid-column: span 3;
  }
  div:nth-child(10) {
    grid-column: span 3;
  }
  div:nth-child(11) {
    grid-column: span 3;
  }
}
