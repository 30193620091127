.modal {
  width: fit-content !important;
}
.input-wrapper{
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-x: auto;
  

  .amount {
    display: flex;
    align-items: center;
    gap: 12px;
  
    p,h4 {
      padding-top: 24px;
    }
  }
}
