.label-font {
  font-size: 16px !important;
}

.label {
  margin: auto 0 !important;
  font-weight: bold;
}

.already-select-container {
  display: grid;
  grid-template-columns: 0.5fr 2px 1fr;

  .input-field {
    align-self: baseline;
  }
}

.select {
  min-width: 100px !important;
  width: 100px !important;
}
