.wrapper {
  width: 400px;
  display: flex;
  justify-content: center;

  & > .modalBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > .buttonsWrapper {
      width: 70%;
      display: flex;
      justify-content: space-between;
      margin-top: var(--mzn-spacing-4);
    }
  }
}
