.wrapper {
  width: 100%;
  border: 1px solid var(--mzn-color-divider);
  padding: var(--mzn-spacing-2) var(--mzn-spacing-4);

  &>.header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &>.border {
    width: 100%;
    height: var(--mzn-spacing-4);
    border-bottom: 1px solid var(--mzn-color-divider);
    margin-bottom: var(--mzn-spacing-4);
  }

  & .filter {
    display: flex;
    flex-wrap: wrap;
    gap: var(--mzn-spacing-4) var(--mzn-spacing-5);
  }

  & .button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: var(--mzn-spacing-4);
  }

  & .noRotateIcon {
    transform: rotate(0);
    transition: 0.3s;
  }

  & .rotateIcon {
    transform: rotate(180deg);
    transition: 0.3s;
  }

  & .displayNone {
    display: none;
  }
}

.tagForm {
  width: 100%;

  & .border {
    width: 100%;
    height: var(--mzn-spacing-4);
    margin-bottom: var(--mzn-spacing-4);
    border-bottom: 1px solid var(--mzn-color-divider);
  }

  &>.formWrapper {
    display: flex;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: var(--mzn-spacing-4);

    &>.tags {
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      gap: var(--mzn-spacing-4);
    }
  }
}

.space {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  align-items: flex-end;
}