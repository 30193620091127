.page_content {
  :global(.ant-descriptions) {
    :global(.ant-descriptions-view) {
      border-radius: 0;
      :global(.ant-form-item) {
        margin-bottom: 0;
      }
    }
  }
}
