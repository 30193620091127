.wrapper {
  border: 1px solid var(--mzn-color-divider);
  padding: var(--mzn-spacing-4);
}

.divider {
  margin: var(--mzn-spacing-2) 0 !important;
}

.filterGrid {
  padding-top: var(--mzn-spacing-2);
  display: grid;
  grid-template-columns: 150px 1fr 150px 1fr;
  gap: var(--mzn-spacing-5);
  align-items: center;
}

.filterGrid > *:nth-child(odd) {
  // background-color: green;
}

.filterGrid > *:nth-child(even) {
  // background-color: blue;
}
