.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: var(--mzn-spacing-4);
  row-gap: var(--mzn-spacing-6);
  justify-content: flex-end;
  background-color: var(--mzn-color-bg);
  border-radius: var(--mzn-spacing-2);
  padding: var(--mzn-spacing-6);
  width: 100%;
}

.title {
  grid-column: span 4;
}

.otherInfoNote {
  grid-column: span 4;
  > div {
    --width: 100% !important;
  }
}

.otherInfoSelectors {
  grid-column: span 2;
}

.otherInfoCheckboxes {
  grid-template-rows: repeat(5, 1fr);
  gap: 0 !important;
  &:global(.mzn-rhf-checkbox-group) {
    label span {
      color: var(--mzn-color-text-primary);
    }
  }
}
