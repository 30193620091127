.panelContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.tableContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  row-gap: var(--mzn-spacing-2);
  border: 1px solid var(--mzn-color-border);
  padding: var(--mzn-spacing-3);
}

.tableCaption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: var(--mzn-spacing-2);
  width: 100%;
}

.formContainer {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  row-gap: 8px;

  h4 {
    margin: 0 0 12px 0;
    border-bottom: 1px solid #e2e2e2;
  }
}

.scanInput {
  width: 100%;
  input {
    width: 100%;
  }
}
