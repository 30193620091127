.wrapper {
  & > .label {
    height: 24px;
    display: flex;
    align-items: flex-start;
  }
}

.border {
  background: var(--mzn-color-divider);
  display: block;
  height: 1px;
}

.borderVertical {
  background: var(--mzn-color-divider);
  display: block;
  width: 1px;
}

.infoWrapper {
  padding-top: var(--mzn-spacing-2);

  & > .info {
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}

.radioWrapper {
  display: flex;
  gap: var(--mzn-spacing-2);

  & > .radioGroup {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    gap: var(--mzn-spacing-5);

    & .radio {
      display: flex;
      gap: var(--mzn-spacing-5);
    }

    & .verticalBorder {
      width: 1px;
      height: 56px;
      background: var(--mzn-color-divider);
    }

    & .borderBottom {
      width: 100%;
      height: var(--mzn-spacing-4);
      border-bottom: 1px solid var(--mzn-color-divider);
    }
  }
}

.accountInput {
  width: 72px;
  height: 32px;
  outline: none;
  border: 1px solid var(--mzn-color-divider);
  background: #ffffff;
  text-align: center;
}

.accountInput::-webkit-outer-spin-button,
.accountInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.accountInput[type='number'] {
  -moz-appearance: textfield;
}

.sizeInputs {
  display: flex;
  gap: var(--mzn-spacing-1);

  & .typography {
    display: flex;
  }

  & .input {
    display: flex;
    gap: var(--mzn-spacing-1);
  }
}

.x {
  height: 32px;
  display: flex;
  align-items: center;
}

.modalInput {
  outline: none;
  border: 1px solid var(--mzn-color-divider);
  background: #fff;
  height: 32px;
  width: 172px;
  padding: 0 var(--mzn-spacing-4);
}

.modalSearchInputLabel {
  margin-bottom: var(--mzn-spacing-1);
}
