.wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 480px 1fr;
  column-gap: 16px;
}

.placeholder {
  padding: 40px 0;
}

.controlPlane {
  margin: 0;
  border-right: 1px solid var(--mzn-color-secondary);
  padding: 12px 16px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  row-gap: 12px;
}

.typeSelector {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 12px;
}

.fieldOptionsWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  row-gap: 8px;
  width: 280px;
}

.option {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  column-gap: 12px;
}

.formItems {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  row-gap: 12px;
}

.formField {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  column-gap: 12px;
}

.footer {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  column-gap: 6px;
}
