.wrapper {
  width: fit-content;
  display: flex;
  gap: var(--mzn-spacing-2);
  align-items: center;
}

.breakComponent {
  width: 100%;
}

.input {
  border: 1px solid var(--mzn-color-divider);
  outline: none;
  width: 160px;
  height: 32px;
  padding: 0 var(--mzn-spacing-4);
}

.accountInputWrapper {
  border: 1px solid var(--mzn-color-divider);
  width: 160px;
  height: 32px;
  padding: 0 var(--mzn-spacing-4);
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > .accountInput {
    width: 64px;
    height: 100%;
    outline: none;
    border: none;
    text-align: center;
  }

  & > .accountInput::-webkit-outer-spin-button,
  & > .accountInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & > .accountInput[type='number'] {
    -moz-appearance: textfield;
  }

  & > .button {
    width: auto;
    height: 100%;
    display: flex;
    align-items: center;
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
  }
}

.treeSelect {
  flex: 1;
  border: 1px solid var(--mzn-color-divider);
}
