.wrapper {
  display: grid;
  position: relative;
  border: 1pt solid black;
  border-radius: 20px;
  padding: 12px;
  height: 100%;
  cursor: pointer;
  font-size: 18px;
  line-height: 16px;
  gap: var(--mzn-spacing-1);
  grid-template-areas:
    'label_header top_label_barcode'
    'top_content top_content'
    'left_content bottom_label_barcode';
  grid-template-columns: auto min-content;
  grid-template-rows: max-content max-content auto;
  page-break-before: always;

  .label_header {
    grid-area: label_header;
    width: 100%;
    height: 36px;
    > img {
      height: 100%;
    }
  }

  .label_content {
    display: grid;
    grid-template-columns: 100px auto;
    align-items: center;
    gap: var(--mzn-spacing-1);
  }

  .top_content {
    grid-area: top_content;
  }

  .left_content {
    grid-area: left_content;
  }

  .top_label_barcode {
    grid-area: top_label_barcode;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .bottom_label_barcode {
    grid-area: bottom_label_barcode;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

.focused-wrapper {
  box-shadow: 0px 5px 5px 1px;
}

.trash-icon {
  position: absolute !important;
  right: 0 !important;
  top: 0 !important;
}

.description {
  display: block;
  max-width: 300pt;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.remark {
  display: block;
  max-width: 250pt;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.analyze-barcode {
  position: absolute;
  bottom: 20px;
  right: 30px;
}
