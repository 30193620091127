.wrapper > * + * {
  margin-top: var(--mzn-spacing-6);
}

.form {
  display: flex;
  flex-direction: column;
  gap: var(--mzn-spacing-5);
}

.formLabel {
  letter-spacing: var(--mzn-typography-h6-letter-spacing);
  color: var(--mzn-color-action-inactive);
}

.wordWrap {
  word-break: break-all;
  white-space: pre-wrap;
}
