/* Your styles goes here. */
$header: header;
$sidebar: sidebar;
$page: page;

.container {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-areas: '#{$header} #{$header}''#{$sidebar} #{$page}';
  grid-template-columns: 256px calc(100vw - 256px);
  grid-template-rows: 65px 1fr; //minmax(0, 1fr);

  &--side-bar-hidden {
    --site-table-width: 1fr;

    grid-template-columns: max-content 100vw;
  }
}

.#{$page} {
  grid-area: $page;
  position: relative;
  scroll-behavior: smooth;
  z-index: 0;
}

.main {
  width: 100%;
  height: calc(100vh - 65px);
  border-left: 1px solid var(--mzn-color-border);
  padding: 20px 40px 20px 40px;
  overflow: auto;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

:global(.mzn-table__body) {
  > :global(.mzn-table__body__row):nth-child(even) {
    background: rgba(243, 243, 243, 0.5);
  }
}

// custom ant design select-tree component styles
:global(.ant-select-tree-list
    .ant-select-tree-treenode[aria-selected='false']
    .ant-select-tree-node-content-wrapper) {
  background: #f3f3f3;
  cursor: auto;
}

.#{$header} {
  grid-area: $header;
}

.#{$sidebar} {
  height: 100%;
  grid-area: $sidebar;
  z-index: 100;
  overflow-y: scroll;
  transform: translateX(0);
  margin-right: 0px;

  transition: transform 0.1s ease-out;

  &--hidden {
    transform: translateX(-256px);
    margin-right: -256px;
  }

  // to deal with mezzaine app bar default style; you can try to remove to check differences
  background-image: linear-gradient(to bottom, #ededed 0%, #ededed),
    linear-gradient(to bottom, #ededed, #ededed) !important;
  padding-right: 0px !important;
}
