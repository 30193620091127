.container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: var(--mzn-spacing-2);
  row-gap: var(--mzn-spacing-3);
  background-color: var(--mzn-color-bg);
  border-radius: var(--mzn-spacing-2);
  padding: var(--mzn-spacing-6);
  width: 100%;
}

.buttonGroup {
  grid-column: 3 / -1;
  justify-content: end;
  margin-top: 20px;
}

.searchBtn {
  grid-column-start: 1;
  grid-column-end: 5;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.newBtn {
  align-self: center;
}

.tableCellBtn {
  padding: var(--mzn-spacing-2) !important;
}

.table {
  tr:has(.unConfirmed) {
    background-color: var(--mzn-color-warning-hover-bg) !important;
  }
  tr:has(.deleted) {
    & > td {
      backdrop-filter: brightness(80%);
    }
  }
  td {
    background-color: unset !important;
  }
}

.modalBody {
  padding: var(--mzn-spacing-9) var(--mzn-spacing-6) var(--mzn-spacing-8) !important;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border-radius: var(--mzn-spacing-2);
  width: 100%;
  > div {
    min-height: 3rem;
    border: 0.5px solid var(--mzn-color-border);
    display: flex;
    align-items: center;
    padding: var(--mzn-spacing-4);
  }
}

.modalTableHeader {
  background-color: #f5f5f5;
  justify-content: center;
}

.wordBreak {
  word-break: break-all;
}

.expandedRow {
  & > * {
    background-color: var(--mzn-color-divider) !important;
  }
}

.exportBtn {
  height: 32px;
  display: flex;
  justify-content: flex-end;
}
